import qs from "qs";
import axios, { AxiosRequestConfig } from "axios";
import { omit, pick } from "lodash";
import {
  AssociateTitleSpecialistDashboardData,
  CreateTitleOrderDocEtaPostRequest,
  CreateTitleOrderTaskDocsReceivedEventPostRequest,
  CreateTitleOrderTaskNoteRequest,
  CreateTitleOrderTaskReopenTasksEventPostRequest,
  DashboardTitleOrder,
  GetAllTitleOrderDocEtasQuery,
  GetTitleOrderDocEtaQuery,
  GetTitleOrderQuery,
  GetTitleOrdersArgs,
  GetTitleOrderTaskDocsEventResponse,
  GetTitleOrderTaskDocsReceivedEventRequest,
  isAssociateTitleSpecialistDashboardData,
  isDashboardTitleOrder,
  //isGetNotificationEventResponse,
  isGetTitleOrderTaskDocsEventResponse,
  isMarkAllNotificationEventsAsReadResponse,
  isTaskNoteCreateResponseSchema,
  isTitleOrderDashboardData,
  isTitleOrderDocEtaListResponse,
  isTitleOrderDocEtaResponse,
  isTitleOrderEscalationDashboardData,
  isTitleOrderTaskDocsEventReceivedResponse,
  isTitleOrderTaskDocsEventReopenTasksResponse,
  isTitleOrderTaskNotesResponse,
  isTitleOrderTasksResponse,
  isTitleOrderWithStatusChangeEvents,
  isTitlesEngineEscalation,
  isUpdateTitleOrderTaskResponse,
  NotificationEvent,
  TaskContact,
  TaskNote,
  TitleOrderDashboardData,
  TitleOrderDocEta,
  TitleOrderEscalationDashboardData,
  TitleOrderTaskDocsEventReceivedResponse,
  TitleOrderTaskDocsEventReopenTasksResponse,
  TitleOrderTasks,
  TitleOrderWithStatusChangeEvents,
  TitlesEngineEscalation,
  UpdateTitleOrderTaskPostRequest,
  UpdateTitleOrderTaskResponseWithMetaData,
  UserInfo,
  WORKFLOW_HOST_URL,
} from "./core/types";
import { titleOrderDocEtaResponse, titleOrderTaskResponse } from "./core/converters";
import { isTitlesEngineTitleOrderWithAssociations, TitlesEngineTitleOrderWithAssociations } from "./title_orders/types";
import { AeLeadStatus, AeRole } from "@auto-approve/auto-approve-ui-library";

export async function getEscalationDashboardData(token: string): Promise<TitleOrderEscalationDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/escalations-dashboard`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderEscalationDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be an escalation dashboard response.\nReceived: ${response?.data}`)
    );
  });
}

export async function getOrderWithStatusHistory(
  request: { titleOrderId: number; newStatus?: AeLeadStatus; oldStatus?: AeLeadStatus },
  token: string
): Promise<TitleOrderWithStatusChangeEvents> {
  const { titleOrderId } = request;

  const url = `${WORKFLOW_HOST_URL}/title-order/${titleOrderId}/status-change-history`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: {
      ...pick(request, ["newStatus", "oldStatus"]),
    },
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderWithStatusChangeEvents(response.data)) {
      return response.data;
    }
    return Promise.reject(
      new Error(
        `Expected response payload type to be a title order with status change events.\nReceived: ${JSON.stringify(
          response?.data
        )}`
      )
    );
  });
}

export async function refreshTitleOrder(token: string, leadId: string): Promise<DashboardTitleOrder> {
  const config: AxiosRequestConfig = {
    method: "post",
    baseURL: WORKFLOW_HOST_URL,
    url: "/refresh-title-order-from-ae",
    headers: {
      Authorization: token,
    },
    params: {
      aeLeadId: leadId,
    },
  };

  return axios(config).then((response) => {
    if (isDashboardTitleOrder(response.data)) {
      return response.data.aeLeadId === leadId
        ? Promise.resolve(response.data)
        : Promise.reject(new Error("Received a title order with a leadId that does not match the query"));
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a work-queue row.\nReceived: ${JSON.stringify(response?.data)}`)
    );
  });
}

export async function getTitleSpecialistWorkQueue(token: string): Promise<TitleOrderDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/title-specialist-work-queue`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a work-queue response.\nReceived: ${response?.data}`)
    );
  });
}

export async function getAssociateTitleSpecialistDashboardData(
  token: string
): Promise<AssociateTitleSpecialistDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/populate-ats-dashboard`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.get(url, config).then((response) => {
    if (isAssociateTitleSpecialistDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDashboardData response.\nReceived: ${response?.data}`)
    );
  });
}

export async function getAssociateTitleSpecialistMyQueueDashboardData(
  token: string
): Promise<AssociateTitleSpecialistDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/populate-ats-my-queue-dashboard`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.get(url, config).then((response) => {
    if (isAssociateTitleSpecialistDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDashboardData response.\nReceived: ${response?.data}`)
    );
  });
}

export async function getTasks(aeLeadId: string, contact: TaskContact, token: string): Promise<TitleOrderTasks> {
  const url = `${WORKFLOW_HOST_URL}/tasks/${contact}`;

  const body = {
    aeLeadId,
  };

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.post(url, body, config).then((response) => {
    const { data } = response;
    if (isTitleOrderTasksResponse(data)) {
      return Promise.resolve({
        group: data.group,
        tasks: data.tasks.map(titleOrderTaskResponse.toTitleOrderTask),
      });
    }
    return Promise.reject(
      new Error(
        `Expected response payload type to be a TitleOrderTaskArray.\nReceived: ${JSON.stringify(response.data)}}`
      )
    );
  });
}

export async function updateTitleOrderTask(
  updateRequest: UpdateTitleOrderTaskPostRequest,
  token: string
): Promise<UpdateTitleOrderTaskResponseWithMetaData> {
  const url = `${WORKFLOW_HOST_URL}/tasks/update`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.post(url, updateRequest, config).then((response) => {
    if (response.status === 201 && isUpdateTitleOrderTaskResponse(response.data)) {
      return { ...response.data, updated: true };
    } else if (response.status === 204) {
      return { updated: false };
    }

    return Promise.reject(
      new Error(
        `Expected response payload type to be a UpdateTitleOrderTaskResponse.\nReceived: ${JSON.stringify(
          response.data
        )}}`
      )
    );
  });
}

export async function createDocsEventReceived(
  createRequest: Omit<CreateTitleOrderTaskDocsReceivedEventPostRequest, "docsReceived">,
  token: string
): Promise<TitleOrderTaskDocsEventReceivedResponse> {
  const url = `${WORKFLOW_HOST_URL}/tasks/doc-events/create`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.post(url, { ...createRequest, docsReceived: true }, config).then((response) => {
    if (response.status === 201 && isTitleOrderTaskDocsEventReceivedResponse(response.data)) {
      return response.data;
    }

    return Promise.reject(
      new Error(
        `Expected response payload type to be a TitleOrderTaskDocsEventReceivedResponse.\nReceived: ${JSON.stringify(
          response.data
        )}}`
      )
    );
  });
}

export async function createDocsEventReopenTasks(
  createRequest: Omit<CreateTitleOrderTaskReopenTasksEventPostRequest, "docsReceived">,
  token: string
): Promise<TitleOrderTaskDocsEventReopenTasksResponse> {
  const url = `${WORKFLOW_HOST_URL}/tasks/doc-events/create`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.post(url, { ...createRequest, docsReceived: false }, config).then((response) => {
    if (response.status === 201 && isTitleOrderTaskDocsEventReopenTasksResponse(response.data)) {
      return response.data;
    }

    return Promise.reject(
      new Error(
        `Expected response payload type to be a TitleOrderTaskDocsEventReopenTasksResponse.\nReceived: ${JSON.stringify(
          response.data
        )}}`
      )
    );
  });
}

export async function getNewestDocEvent(
  query: GetTitleOrderTaskDocsReceivedEventRequest,
  token: string
): Promise<GetTitleOrderTaskDocsEventResponse> {
  const url = `${WORKFLOW_HOST_URL}/tasks/doc-events`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: query,
  };

  return axios.get(url, config).then((response) => {
    if (response.status === 200 && isGetTitleOrderTaskDocsEventResponse(response.data)) {
      return response.data;
    }

    return Promise.reject(
      new Error(
        `Expected response payload type to be a GetTitleOrderTaskDocsEventResponse.\nReceived: ${JSON.stringify(
          response.data
        )}}`
      )
    );
  });
}

export async function getTitleOrderTaskNotes(titleOrderId: number, token: string) {
  const url = `${WORKFLOW_HOST_URL}/tasks/notes`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: {
      titleOrderId,
    },
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderTaskNotesResponse(response.data)) {
      return response.data;
    }
    return Promise.reject(
      new Error(
        `Expected response payload type to be a TitleOrderTaskNotesResponse.\nReceived: ${JSON.stringify(
          response.data
        )}`
      )
    );
  });
}

export async function createTitleOrderTaskNote(
  request: CreateTitleOrderTaskNoteRequest,
  token: string
): Promise<TaskNote> {
  const url = `${WORKFLOW_HOST_URL}/tasks/note`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const { user } = request;

  const payload = {
    ...omit(request, ["user"]),
    userId: user.teUserId,
  };

  return axios.post(url, payload, config).then((response) => {
    if (isTaskNoteCreateResponseSchema(response.data)) {
      return response.data.note;
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TaskNote.\nReceived: ${JSON.stringify(response.data)}`)
    );
  });
}

export async function createTitleOrderDocEta(
  request: CreateTitleOrderDocEtaPostRequest,
  token: string
): Promise<TitleOrderDocEta> {
  const url = `${WORKFLOW_HOST_URL}/tasks/eta`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const endOfDayRequest: CreateTitleOrderDocEtaPostRequest = { ...request, date: request.date.endOf("day") };

  return axios.post(url, endOfDayRequest, config).then((response) => {
    if (isTitleOrderDocEtaResponse(response.data)) {
      return titleOrderDocEtaResponse.toTitleOrderDocEta(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDocEta.\nReceived: ${JSON.stringify(response.data)}`)
    );
  });
}

export async function getTitleOrderDocEta(query: GetTitleOrderDocEtaQuery, token: string): Promise<TitleOrderDocEta> {
  const url = `${WORKFLOW_HOST_URL}/tasks/eta`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: query,
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderDocEtaResponse(response.data)) {
      return titleOrderDocEtaResponse.toTitleOrderDocEta(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDocEta.\nReceived: ${JSON.stringify(response.data)}`)
    );
  });
}

export async function getAllTitleOrderDocEtas(
  query: GetAllTitleOrderDocEtasQuery,
  token: string
): Promise<TitleOrderDocEta[]> {
  const url = `${WORKFLOW_HOST_URL}/tasks/eta`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: query,
  };

  return axios.get(url, config).then((response) => {
    const { data } = response;
    if (isTitleOrderDocEtaListResponse(data)) {
      return data.documentEtas.map(titleOrderDocEtaResponse.toTitleOrderDocEta);
    }
    return Promise.reject(
      new Error(
        `Expected response payload type to be an array of TitleOrderDocEta objects.\nReceived: ${JSON.stringify(
          response.data
        )}`
      )
    );
  });
}

export async function assignTitleOrdersToAts(token: string, aeLeadIds: string[], userId: string): Promise<boolean> {
  const url = `${WORKFLOW_HOST_URL}/assign-ats-to-title-orders`;

  const body = {
    aeLeadIds: aeLeadIds,
    assignedAssociateTitleSpecialistId: userId,
  };

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios.post(url, body, config).then((response) => {
    return response.status === 200;
  });
}

export async function getTitleSpecialistDashboardData(
  token: string,
  take?: number | "all",
  skip = 0
): Promise<TitleOrderDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/populate-ts-dashboard`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: {
      take,
      skip,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDashboardData response.\nReceived: ${response?.data}`)
    );
  });
}

export async function getManagerDashboardData(args: GetTitleOrdersArgs): Promise<TitleOrderDashboardData> {
  const url = `${WORKFLOW_HOST_URL}/populate-manager-dashboard`;

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: args.token,
    },
    params: args.query,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "indices" }),
  };

  return axios.get(url, config).then((response) => {
    if (isTitleOrderDashboardData(response.data)) {
      return Promise.resolve(response.data);
    }
    return Promise.reject(
      new Error(`Expected response payload type to be a TitleOrderDashboardData response.\nReceived: ${response?.data}`)
    );
  });
}

export async function requestEscalation(
  token: string,
  managerId: string,
  description: string,
  aeLeadId: string
): Promise<TitlesEngineEscalation> {
  const url = `${WORKFLOW_HOST_URL}/escalation-request`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  const data = {
    managerId,
    description,
    aeLeadId,
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      if (isTitlesEngineEscalation(response.data)) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(
        new Error(
          `Expected response payload type to be a Titles Engine Escalation response.\nReceived: ${response?.data}`
        )
      );
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

export async function updateProgressOfTitleOrder(args: {
  request: {
    aeLeadId: string;
    desiredStatus: number;
  };
  token: string;
}) {
  const url = `${WORKFLOW_HOST_URL}/update-progress`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: args.token,
    },
  };

  const data = {
    aeLeadId: args.request.aeLeadId,
    status: args.request.desiredStatus,
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      if (response.status === 204) {
        return Promise.resolve(response.status);
      }
      return Promise.reject(new Error(`Did not receive a 204 response from the server. Received: ${response.status}`));
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

export async function updateProgressOfTitleOrderWithNote(args: {
  request: {
    aeLeadId: string;
    desiredStatus: number;
    teUserId: number;
    note: string;
    titleOrderId: number;
  };
  token: string;
}): Promise<DashboardTitleOrder> {
  const url = `${WORKFLOW_HOST_URL}/update-progress-with-note`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: args.token,
    },
  };

  const data = {
    aeLeadId: args.request.aeLeadId,
    status: args.request.desiredStatus,
    userId: args.request.teUserId,
    note: args.request.note,
    titleOrderId: args.request.titleOrderId,
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      if (response.status === 201) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(new Error(`Did not receive a 201 response from the server. Received: ${response.status}`));
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

export async function updateLastTouched(args: {
  request: {
    aeLeadId: string;
  };
  token: string;
}): Promise<DashboardTitleOrder> {
  const url = `${WORKFLOW_HOST_URL}/update-last-touched`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: args.token,
    },
  };

  const data = {
    aeLeadId: args.request.aeLeadId,
  };

  return axios
    .post(url, data, config)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(new Error(`Did not receive a 200 response from the server. Received: ${response.status}`));
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
}

export async function getTitleOrderById(
  id: number,
  token: string,
  refresh?: boolean
): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: {
      id,
      refresh: refresh ? true : undefined
    },
    headers: {
      Authorization: token,
    },
  };

  const response = await axios.get(url, config);

  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }

  return Promise.reject("Unable to retrieve title order by ID");
}

export async function getTitleOrderByAeLeadId(
  aeLeadId: string,
  token: string,
  refresh?: boolean
): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: {
      aeLeadId,
      refresh: refresh ? true : undefined
    },
    headers: {
      Authorization: token,
    },
  };

  const response = await axios.get(url, config);

  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }

  return Promise.reject("Unable to retrieve title order by ID");
}

export async function getTitleOrderByRecordId(
  token: string,
  recordId: number
): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: {
      aeRecordId: recordId,
    },
    headers: {
      Authorization: token,
    },
  };

  const response = await axios.get(url, config);

  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }

  return Promise.reject("Unable to retrieve title order by ID");
}

export async function getTitleOrder(
  query: GetTitleOrderQuery,
  token: string,
): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: query,
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to retrieve title order.");
}

export async function getRefreshedTitleOrderByRecordId(
  token: string,
  recordId: number
): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: {
      aeRecordId: recordId,
      refresh: true
    },
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to retrieve refreshed title order by record ID");
}

export async function getRefreshedTitleOrderByLeadId(
  token: string,
  leadId: string
  ): Promise<TitlesEngineTitleOrderWithAssociations> {
  const url = `${WORKFLOW_HOST_URL}/title-order`;
  const config: AxiosRequestConfig = {
    params: {
      aeLeadId: leadId,
      refresh: true
    },
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  if (isTitlesEngineTitleOrderWithAssociations(response.data)) {
    return response.data;
  }
  return Promise.reject("Unable to retrieve title order by lead ID");
}

// TODO - AAT-6225 - functionality never implemented
export async function getNotificationEvents(_aeUserId: string, _token: string): Promise<NotificationEvent[]> {
  return [];
  // const url = `${WORKFLOW_HOST_URL}/notification-event/${aeUserId}`;
  // const config: AxiosRequestConfig = {
  //   headers: {
  //     Authorization: token,
  //   },
  // };

  // const response = await axios.get(url, config);
  // if (isGetNotificationEventResponse(response.data)) {
  //   return response.data;
  // }

  // return Promise.reject("Unable to retrieve notification events");
}

export async function markAllNotificationsAsRead(aeUserId: string, token: string) {
  const url = `${WORKFLOW_HOST_URL}/notification-event/mark-read/${aeUserId}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
  };

  return axios
    .put(url, {}, config)
    .then((response) => {
      if (!isMarkAllNotificationEventsAsReadResponse(response.data)) {
        return Promise.reject(
          new Error(
            `Expected response payload type to be a MarkAllNotificationEventsAsReadResponse response.\nReceived: ${response.data}`
          )
        );
      }
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      console.error(err);
      return Promise.reject("Unable to mark all notifications as read");
    });
}

export async function getUsersByRole(
  role: AeRole,
  token: string
): Promise<UserInfo[]> {
  const url = `${WORKFLOW_HOST_URL}/users`;
  const config: AxiosRequestConfig = {
    params: {
      role
    },
    headers: {
      Authorization: token,
    },
  };
  return (await axios.get(url, config)).data;
}
